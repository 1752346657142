import type { CookieRef } from '#app'

export const useConfirmCountry = () => {
  const confirmCountryCookie: CookieRef<boolean | undefined> = useCookie(
    'show_confirm_country'
  )

  const localState = useState<boolean>('local-state', () => false)

  onMounted(async () => {
    if (
      typeof confirmCountryCookie.value === 'undefined' ||
      confirmCountryCookie.value
    ) {
      await nextTick()
      localState.value = true
      confirmCountryCookie.value = false
    }
  })

  const open = () => {
    confirmCountryCookie.value = true
    localState.value = true
  }

  const disable = () => {
    if (typeof confirmCountryCookie.value === 'boolean') {
      confirmCountryCookie.value = false
      localState.value = false
    }
  }

  const isOpen = computed(() => localState.value)

  return {
    isOpen,
    disable,
    open,
  }
}
